<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="desc">系统将根据员工选择的印章自动匹配可选的盖印人，并且支持员工选择多个印章</div>
  <!-- 普通申请显示 -->
  <div class="content" v-if="processType == 1">
    <p>申请印章：必选，员工可选该流程名称关联的印章</p>
    <p>申请次数：必填</p>
    <!-- <p>是否外带：非必选，选择后需要填写外带起止时间</p> -->
    <p class="flex-layout">
      <span style="width: 114px"> 盖印人：</span
      ><span style="letter-spacing: 0.8px">系统根据员工所选印章自动带出可选项， 可选项为申请人及对应印章保管员</span>
    </p>
  </div>
  <!-- 自助申请显示内容 -->
  <div class="content" v-else>
    <p>申请印章：必选，员工可选该流程名称关联的印章</p>
    <p>申请次数：必填，填写后员工只能在限定次数内用印</p>
  </div>
  <div v-if="processType == 1" class="stamp-sty">
    <a-form layout="vertical">
      <a-form-item label="控件名称" name="title" required>
        <a-input v-model:value="stampObj.title" placeholder="请输入" :maxLength="6" :disabled="true" />
      </a-form-item>
    </a-form>
    <div>
      <div>申请人可选盖印人</div>
      <div style="display: flex; margin-top: 12px;">
        <a-checkbox-group
          v-model:value="checkedList"
          style="width: 100px;"
          @change="checkedChange"
          :disabled="allDisabled"
        >
          <a-row>
            <a-col :span="18" v-for="item in plainOptions" :key="item" style="margin-bottom: 8px;">
              <a-checkbox :value="item">{{ item }}</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <div>
          <a-radio-group v-model:value="radio" @change="radioChange" :disabled="allDisabled">
            <a-radio
              v-for="item in radioList"
              :key="item.value"
              :value="item.value"
              class="radio"
              :disabled="item.disabled"
            >
              默认选中
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>
    <a-form-item label="" name="required">
      <a-checkbox :disabled="true" v-model:checked="stampObj.props.required">这是一个必填项</a-checkbox>
    </a-form-item>
  </div>
</template>

<script setup>
import { defineProps, computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const { config, allDisabled } = defineProps(['config', 'allDisabled'])

const store = useStore()
const processType = computed(() => store.state.process.basic.processType)
const stampObj = computed(() => config.items[2])
const radio = ref(1)
const checkedList = ref([])
const plainOptions = ref(['申请人'])
const radioList = ref([
  { value: 1, disabled: true },
  // { value: 2, disabled: false }
])
onMounted(() => {
  console.log('1111', config.items[2])
  if(!config.items[2]) return
  if (!config.items[2].props.stamp) {
    config.items[2].props = {
      required: true,
      stamp: [
        { title: '申请人', isCheck: false },
        // { title: '保管员', isCheck: true }
      ]
    }
  }
  config.items[2].props.required = true
  config.items[2].props.stamp &&
    config.items[2].props.stamp.forEach((item, index) => {
      console.log('99182882882828', item)
      checkedList.value.push(item.title)
      if (config.items[2].props.stamp.length === 1) {
        // 如果只有一个
        const ind = plainOptions.value.findIndex(val => val === item.title)
        console.log(ind)
        if (ind !== -1) {
          // radio.value = ind + 1
          radioList.value.forEach(item => {
            item.disabled = true
          })
        }
      } else {
        if (item.isCheck) {
          // radio.value = index + 1
        }
      }
    })
})

// 多选框改变
const checkedChange = value => {
  // console.log(value);
  if (value.length !== 0) {
    if (value.length === 1) {
      config.items[2].props.stamp = [{ title: value[0], isCheck: true }]
      const stampIndex = plainOptions.value.findIndex(item => item === value[0])
      radio.value = stampIndex + 1
      // 只剩下一个时 单选框选中置灰
      radioList.value.forEach(item => {
        item.disabled = true
      })
    }
    if (value.length === 2) {
      config.items[2].props.stamp = [
        { title: '申请人', isCheck: false },
        // { title: '保管员', isCheck: false }
      ]
      config.items[2].props.stamp[radio.value - 1].isCheck = true
      radioList.value.forEach(item => {
        item.disabled = false
      })
    }
  } else {
    radio.value = null
    config.items[2].props.stamp = []
  }
  checkedList.value = value
}

const radioChange = e => {
  // console.log(e, checkedList.value)
  const value = e.target.value
  if (checkedList.value.length > 1) {
    config.items[2].props.stamp.forEach(item => {
      item.isCheck = false
    })
    config.items[2].props.stamp[Number(value - 1)].isCheck = true
  }
}
</script>

<style lang="less" scoped>
.desc {
  // text-align: left;
  background: #fbfbfb;
  padding: 24px;
  line-height: 26px;
  letter-spacing: 0.5px;
}
.content {
  padding: 8px 24px;
  color: #333;
  p {
    padding: 16px 0;
    border-bottom: 1px solid #f5f5f5;
  }
}
.stamp-sty {
  padding: 24px;
}
.radio {
  display: block;
  line-height: 24px;
  height: 28px;
}
</style>
